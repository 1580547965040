.redes {
    display: block;
    position: fixed;
    bottom: 3vh;
    right: 1.5vw;
    z-index: 10000;
}

.red {
    display: block;
    font-size: 35px;
    margin: 5px auto;
    color: white ;
}

.text-redes {
    display: inline-block;
    position: relative;
    transform: rotate(90deg);
    color: white ;
    font: menu;
    margin:10px auto;
    margin-top: 90px;
    text-align: center;
}

@media only screen and (max-width: 600px) {

    .redes {
        top: auto;
        bottom: 2vh;
        right: 10px;
    }
    
    .red {
        font-size: 25px;
    }
    
    .text-redes {
        display: none;
    }
    
}