@font-face {
  font-family: 'SFNSText';
  src: url('./font/SFNSDisplay-Regular.otf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'SFNSTextBold';
  src: url('./font/SFNSDisplay-Bold.otf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

/* header */

.logoheader {
  animation-name: logoheader;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  width: 30vw; 
}

@keyframes logoheader {
  from {
    transform: scale(1.05);
  }

  to {
    transform: scale(1);
  }
}

@keyframes bganimate {
  from {
    background: url('./img/giff.gif') center left no-repeat;
  }

  to {
    background: url('./img/giff.gif') center right no-repeat;
  }
}

/* end header */


/*body*/

body {
  background-size: cover;
  background: rgb(45, 0, 128);
  font-family: SFNSText;
}

.h1 {
  font-weight: 800;
  font-size: 5rem;
  font-family: SFNSTextBold;  
}

.h2 {
  font-weight: 800;
  font-size: 3rem;
  font-family: SFNSTextBold;
}

.h3 {
  font-size: 2rem;
  font-family: SFNSTextBold;
  cursor: pointer;
}

.h4 {
  font-size: 1.5rem;
  font-family: SFNSTextBold;
}

.p {
  font-size: 1.7rem;
  font-family: SFNSTextBold;
}

.small {
  font: menu
}

.text-yellow {
  color: rgb(255, 237, 137);
}

.text-purple {
  color: rgb(196, 174, 255);
}

.text-purple-dark {
  color: rgb(81, 19, 252);
  
}

.text-red {
  color: rgb(255, 132, 132);
}


.bg-yellow {
  background: rgb(253, 231, 159);
}

.bg-purple {
  background: rgba(56, 0, 161, 0.85) !important;
}

.bg-purple-dark {
  background: rgba(56, 0, 161, 1) !important;
}

.bg-red {
  background: rgb(255, 101, 101);
}

.bg-dark {
  background: rgba(56, 0, 161, 0.5) !important;
  backdrop-filter: blur(9px);
}

.bg {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  background: url('./img/giff.gif') center center no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  opacity: 0.5;
  z-index: -1;
  filter: contrast(111%) saturate(111%);
}

a {
  color: rgb(253, 231, 159) ;
  text-decoration: none !important;
}

a:hover {
  color: rgb(253, 231, 159) ;
  text-decoration: none;
}


/* end body*/

.previous {
  display: block;
  position: fixed;
  font-size: 100px;
  top: -30px;
  right: 0;
  filter: invert(100%);
}

.previous-dark {
  display: block;
  position: fixed;
  font-size: 111px;
  filter: invert(0%);
}

.filter{
  filter: invert(100%);
  opacity: 0.7;
}



/* forms*/

.rounded {
  border-radius: 30px !important;  
}

/* forms end*/

/* menu */

.close {
  position: absolute;
  filter: invert(100%);
  font-size: 55px;
  top: -15px;
  right: 5px;
  height: 100%;
}

.menuplus {
  display: inline-block;
  position: relative;
}

.menuplus-button {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 40px;
  cursor: pointer;
}

.menuplus-dropdown {
  display: none;
}

/* menu end*/

/* section */
header {
  display: flex;
  align-items: center;
  min-height: 100vh;
}

.space {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
}

.spaceinfo {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
}

.center-y {
  display: flex;
  align-items: center;
  min-height: 100vh;
}

.img-service {
  display: block;
  position: relative;
  width: 450px;
  margin: auto auto;
  border-radius: 30px;
  pointer-events: none;
  animation-name: logoheader;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  border: 5px solid rgba(255, 255, 255, 0.3);
}
/* end section */

/* footer */

.logofooter {
  display: block;
  position: relative;
  margin: auto auto;
  background: url('./img/footer/logo.svg') no-repeat center;
  width: 200px;
  height: 60px;
}

/* end footer */

/* team */

.img-team {
  height:100px;
  width: 100px;
  border-radius: 50px;
  transition-duration: 300ms
}

.img-team:hover {
  transform: scale(1.2);
  transition-duration: 300ms
}

/* end team */


@media only screen and (max-width: 600px) {  
  /* header */

  .bg {
    background: url('./img/giff.gif') center left no-repeat;
    animation-name: bganimate;
    animation-duration: 60s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear
  }
  

  .logoheader {
    width: 70vw
  }

  /* end header */


  /*body*/

  .h1 {
    font-size: 3rem;    
  }

  .h2 {
    font-size: 2rem;
  }

  .h3 {
    font-size: 1rem;
  }

  .h4 {
    font-size: 0.8rem;
  }

  .p, h6 {
    font-size: 0.8rem;
  }


  /* end body*/

  .previous {
    font-size: 60px;
    top: -20px;
    right: -9px;
  }

  .previous-dark {
    font-size: 75px;
    top: 43vh;
    right: 0px;
  }


  /* forms*/

  .rounded {
    border-radius: 25px !important;  
  }

  /* forms end*/

  /* menu */

  .close {
    font-size: 40px;
    top: -7px;
    height: 100%;
  }

  .menuplus {
    display: inline-block;
    position: relative;
  }

  .menuplus-button {
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 30px;
    cursor: pointer;
  }

  .menuplus-dropdown {
    display: none;
    width: 92vw;
  }
  

  /* menu end*/

  /* section */

  .space {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
  }

  .spaceinfo {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
  }

  .center-y {
    display: flex;
    align-items: center;
    min-height: 0vh;
  }

  .img-service {
    width: 100%;
    margin-top: 20px;
  }
  /* end section */

  /* footer */

  .logofooter {
    width: 150px;
  }

  /* end footer */

  /* team */

  .img-team {
    align-items: center;
    width: 60px;
    height: 60px;
  }

  /* end team */

}